import React from "react"

import "./style.scss";

type InputPropsType = {
  value?: string | number | undefined;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  required?: boolean;
  type?: string;
  placeholder?: string;
  Icon?: React.ElementType;
  min?: number | undefined;
  max?: number | undefined;
  disabled?: boolean;
  name?: string;
  step?: string;
  accept?: string;
};

export const Input = ({
  value,
  onChange,
  type,
  placeholder,
  Icon,
  required,
  min,
  max,
  disabled,
  name,
  step,
  accept
}: InputPropsType) => {
  const numberProps = (type === "number") ? {
    min: min,
    max: max,
  } : {};

  return (
    <div className="row">
      {Icon && <Icon className="icon" />}
      <div className="input-box">
        <input
          value={value}
          onChange={onChange}
          type={type}
          placeholder=" "
          required={required}
          {...numberProps}
          disabled={disabled}
          name={name}
          step={step}
          accept={accept}
        />
        <span>{placeholder}</span>
      </div>
    </div>
  );
};

Input.defaultProps = {
  type: "text",
  placeholder: " ",
  disabled: false,
}