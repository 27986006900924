import { FaFileInvoiceDollar } from "react-icons/fa";
import { BsInbox, BsArrowDownSquare } from "react-icons/bs";
import { AiOutlineFieldNumber } from "react-icons/ai";
import useCollapse from 'react-collapsed';
import { CSVLink } from "react-csv";

import { Button, Input, Select } from "../Commons";
import { COLLECTION_EMPLACEMENTS, COLLECTION_TYPES, FAUNES } from "../../api/data";

import "./style.scss";
import { DATA } from "../../api/dataApi";
import { COFFRET } from "src/api/coffrets";

type PropsType = {
  handleModal: () => void;
  handleChangeSearch: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => void;
  search: {
    genre: string,
    espece: string,
    type: string,
    faune: string,
    emplacement: string,
    numeroCoffret: string,
    numeroFacture: string,
    numeroOrdre: string,
  };
  data: DATA;
  coffretsToCsv: COFFRET[];
  csvReport: any;
  resetCoffretsToCsv: () => void;
};

export const TasksManager = ({
  handleModal,
  handleChangeSearch,
  search,
  data,
  coffretsToCsv,
  csvReport,
  resetCoffretsToCsv,
}: PropsType) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  return (
    <div className="tasks-manager">
      <div className="raw">
        <Input
          value={search.genre}
          onChange={handleChangeSearch}
          placeholder="genre ..."
          name="genre"
          required={false}
        />
        <Input
          value={search.espece}
          onChange={handleChangeSearch}
          placeholder="espèce ..."
          name="espece"
          required={false}
        />
        <div className="block-csv">
          {
            (coffretsToCsv.length > 0) && <CSVLink {...csvReport}>EXPORT CSV</CSVLink>
          }
          <Button onClick={resetCoffretsToCsv} value="Reset CSV" />
        </div>
        <Button onClick={handleModal} value="Ajouter un spécimen" />
      </div>
      <div {...getCollapseProps()}>
        <div className="raw">
          <Select name="type" value={search.type} placeholder="TYPE DE COLLECTION" onChange={handleChangeSearch} options={COLLECTION_TYPES} />
          <Select name="emplacement" value={search.emplacement} placeholder="EMPLACEMENT DE CETTE COLLECTION" onChange={handleChangeSearch} options={COLLECTION_EMPLACEMENTS} />
          <Select name="faune" value={search.faune} placeholder="FAUNE" onChange={handleChangeSearch} options={FAUNES} />
        </div>
        <div className="raw">
          <Select name="numeroCoffret" value={search.numeroCoffret} placeholder="N° COFFRET" onChange={handleChangeSearch} Icon={BsInbox} options={data.numerosCoffret} />
          <Select name="numeroFacture" value={search.numeroFacture} placeholder="N° FACTURE" onChange={handleChangeSearch} Icon={FaFileInvoiceDollar} options={data.numerosFacture} />
          <Select name="numeroOrdre" value={search.numeroOrdre} placeholder="N° ORDRE" onChange={handleChangeSearch} Icon={AiOutlineFieldNumber} options={data.numerosOrdre} />
        </div>
      </div>
      <div className="collapse" {...getToggleProps()}>
        <span>
          {isExpanded ? "Filtres" : "Filtres"}
          <BsArrowDownSquare className={isExpanded ? "icon reverse" : "icon"} />
        </span>
      </div>
    </div >
  );
};