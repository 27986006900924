import { AiOutlineLogout } from 'react-icons/ai';

import "./style.scss";

type PropsType = {
  logout: () => void,
};

export const Logout = ({ logout }: PropsType) => {
  return (
    <div className="logout">
      <span onClick={logout} >Déconnexion</span>
      <AiOutlineLogout onClick={logout} />
    </div>
  );
};