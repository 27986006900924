import "./style.scss";
import { GiButterfly } from "react-icons/gi";

export const Header = () => {
  return (
    <div className="header">
      <div className="title">
        <span>COLLECTION&nbsp;&nbsp;</span>
        <span><GiButterfly className="flag" /></span>
        <span>&nbsp;&nbsp;SCIENCES NATURELLES</span>
      </div>
      <div className="typing">
        <div className="typing-effect">FAMILLE REYNAUD</div>
      </div>
    </div>
  );
};