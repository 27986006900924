// LISTE A
export const COLLECTION_TYPES = [
  "LEPIDOPTERE",
  "COLEOPTERE",
  "HYMENOPTERE",
  "FOSSILE",
  "METEORITE",
  "ARACHNIDE",
  "BLATTOPTERE", // NEW
  "CHILOPODE", // NEW
  "DIPTERE", // NEW
  "HEMIPTERE", // NEW
  "HETEROPTERE", // NEW
  "MANTOPTERE", // NEW
  "NEUROPTERE", // NEW
  "ODONATE", // NEW
  "ORNITHOLOGIE", // NEW
  "ORTHOPTERE", // NEW
  "PALEONTOLOGIE", // NEW
  "PHASMOPTERA", // NEW
  "PLECOPTERE", // NEW
  "SCORPION", // NEW
];

// LISTE B
export const COLLECTION_EMPLACEMENTS = [
  "BUREAU",
  "APPARTEMENT",
  "VITRINE",
  "EXPOSITION",
];

// LISTE C
export const FAUNES = [
  "AMERICAINE",
  "AFRICAINE",
  "EUROPEENNE",
  "FRANCAISE",
  "INDO-AUSTRALIENNE",
  "PALEARCTIQUE", // NEW
];

// LISTE E
export const SEXES = [
  "MALE",
  "FEMELLE",
  "GYNANDROMORPHE",
  "SANS OBJET",
  "NON DETERMINE", // NEW
  "COUPLE", // NEW
  "GYNAN", // NEW
];

// LISTE F
export const TYPES_ACHAT = [
  "VENTE ENCHERES",
  "PARTICULIER",
  "ECHANGE",
  "CAPTURE",
  "MAGASIN",
  "CADEAU", //  NEW
  "ELEVAGE", // NEW
];

export const MOCKS_IMG = [
  "https://remeng.rosselcdn.net/sites/default/files/dpistyles_v2/rem_16_9_917w/2022/07/08/node_389198/12820386/public/2022/07/08/B9731464614Z.1_20220708000146_000%2BGB0KRVAC4.1-0.jpg?itok=MXpJ2X_a1657231314",
  "https://cdn.futura-sciences.com/cdn-cgi/image/width=1920,quality=60,format=auto/sources/images/diaporama/679-squelettes-de-dinos/3375077838_232d344a7f_o.jpg",
  "https://animalaxy.fr/wp-content/uploads/2018/11/Capture-d%E2%80%99%C3%A9cran-2018-11-07-%C3%A0-10.59.19.jpg",
  "https://t2.ea.ltmcdn.com/fr/posts/5/8/2/_3285_13_600.webp",
  "https://t1.ea.ltmcdn.com/fr/posts/5/8/2/_3285_8_600.webp",
  "https://image.noelshack.com/fichiers/2015/33/1439323425-pid-406306-24a229aa-9cc8-11e0-a3c2-eb65564a80e4-original.jpg",
  "https://image.noelshack.com/fichiers/2015/33/1439323681-dp.png",
];
