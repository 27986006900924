import { Link } from "react-router-dom";

import { Authentification } from "../../components";

import "./style.scss";

type PropsType = {
  username: string;
  handleChangeUsername: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  password: string;
  handleChangePwd: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  handleSubmit: (e: React.SyntheticEvent) => void;
  loading: boolean;
  title: "Chargement ..." | "Connexion";
};

export const Login = ({
  username,
  handleChangeUsername,
  password,
  handleChangePwd,
  handleSubmit,
  loading,
  title
}: PropsType) => {
  return (
    <>
      <Authentification
        username={username}
        handleChangeUsername={handleChangeUsername}
        password={password}
        handleChangePwd={handleChangePwd}
        handleSubmit={handleSubmit}
        loading={loading}
        title={title}
      />
      {/* <Link to="/register">Je crée un compte</Link> */}
    </>
  );
};