import { useEffect, useContext, useCallback, useState } from "react";

import { MemoizedTasksList as TasksList } from "./TasksList.component"
import { TasksContext } from "../../context/tasks/store";
import { allCoffrets, COFFRET } from "../../api/coffrets";
import { allData } from "../../api/dataApi";

export const TasksListContainer = () => {
  const { coffrets, setCoffrets, search, setData } = useContext(TasksContext);
  const [coffretsShowedNbr, setCoffretsShowedNbr] = useState(20);
  const [coffretsShowed, setCoffretsShowed] = useState([]);
  const [filteredCoffrets, setFilteredCoffrets] = useState([] as COFFRET[]);

  useEffect(() => {
    const fetchCoffrets = async () => {
      try {
        const res = await allCoffrets();
        setCoffrets(res.data);
      } catch (_) {
        setCoffrets([]);
      }
    };
    const fetchData = async () => {
      try {
        const res = await allData();
        setData(res.data);
      } catch (_) {
        console.log("Err")
      }
    };
    fetchCoffrets();
    fetchData();
  }, []);

  useEffect(() => {
    const { genre, espece, type, faune, emplacement, numeroCoffret, numeroFacture, numeroOrdre } = search;
    let newCoffrets: COFFRET[] = [...coffrets];

    if (genre)
      newCoffrets = newCoffrets.filter((coffret: COFFRET) => coffret?.genre?.toUpperCase().includes(genre.toUpperCase()));
    if (espece)
      newCoffrets = newCoffrets.filter((coffret: COFFRET) => coffret?.espece?.toUpperCase().includes(espece.toUpperCase()));
    if (type)
      newCoffrets = newCoffrets.filter((coffret: COFFRET) => coffret.type === type);
    if (faune)
      newCoffrets = newCoffrets.filter((coffret: COFFRET) => coffret.faune === faune);
    if (emplacement)
      newCoffrets = newCoffrets.filter((coffret: COFFRET) => coffret.emplacement === emplacement);
    if (numeroCoffret)
      newCoffrets = newCoffrets.filter((coffret: COFFRET) => coffret.numeroCoffret === numeroCoffret);
    if (numeroFacture)
      newCoffrets = newCoffrets.filter((coffret: COFFRET) => coffret.numeroFacture === numeroFacture);
    if (numeroOrdre)
      newCoffrets = newCoffrets.filter((coffret: COFFRET) => coffret.numeroOrdre === parseInt(numeroOrdre));
    setFilteredCoffrets(newCoffrets);
  }, [search, coffrets]);

  if (coffrets.length)
    return <TasksList
      filteredCoffrets={filteredCoffrets}
      coffretsShowedNbr={coffretsShowedNbr}
      setCoffretsShowedNbr={setCoffretsShowedNbr}
      coffretsShowed={coffretsShowed}
      setCoffretsShowed={setCoffretsShowed}
    />;
  return null;
};
