import "./style.scss";

type SelectPropsType = {
  value: string | undefined | number;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  options: string[] | number[];
  required?: boolean;
  placeholder?: string;
  Icon?: React.ElementType;
  name?: string;
};

export const Select = ({
  value,
  onChange,
  placeholder,
  options,
  Icon,
  required,
  name
}: SelectPropsType) => {
  return (
    <div className="row">
      {Icon && <Icon className="icon" />}
      <div className="input-box">
        <select value={value} onChange={onChange} required={required} name={name} >
          <option value={undefined}></option>
          {options?.map((option, index) => {
            return <option key={index} value={option}>{option}</option>
          })}
        </select>
        <span>{placeholder}</span>
      </div>
    </div>
  );
};

Select.defaultProps = {
  required: false,
}