import { useCallback, useState, useEffect, useContext, useMemo } from "react";

import { TasksContext } from "../../context/tasks/store";
import { TaskForm } from "./TaskForm.component"
import { allCoffrets, updateCoffret, addCoffret } from "../../api/coffrets";
import { allData } from "../../api/dataApi";

async function postImage(image: any, description: string) {
  const token = localStorage.getItem("token");
  let formData = new FormData();
  formData.append("image", image)
  formData.append("description", `${description}.jpg`)
  const result: any = await fetch('/api/image', {
    method: 'POST',
    headers: { Authorization: `Bearer ${token}` },
    body: formData
  })
  return result.data
}

async function postFacture(facture: any, description: string) {
  const token = localStorage.getItem("token");
  let formData = new FormData();
  formData.append("image", facture)
  formData.append("description", `${description}.pdf`)
  const result: any = await fetch('/api/facture', {
    method: 'POST',
    headers: { Authorization: `Bearer ${token}` },
    body: formData
  })
  return result.data
}

export const TaskFormContainer = () => {
  const { coffretToUpdate: coffret, setIsModalOpen, setCoffrets, data, setData }: any = useContext(TasksContext);

  const [loading, setLoading] = useState(false);

  const [type, setType] = useState("");
  const [emplacement, setEmplacement] = useState("");
  const [genre, setGenre] = useState("");
  const [espece, setEspece] = useState("");
  const [faune, setFaune] = useState("");
  const [sexe, setSexe] = useState("");
  const [quantite, setQuantite] = useState(1);
  const [prixAchat, setPrixAchat] = useState("0");
  const [dateCapture, setDateCapture] = useState("");
  const [typeAchat, setTypeAchat] = useState("");
  const [detailsCapture, setDetailsCapture] = useState("");
  const [detailsAchat, setDetailsAchat] = useState("");
  const [numeroCoffret, setNumeroCoffret] = useState("");
  const [numeroFacture, setNumeroFacture] = useState("");
  const [numeroOrdre, setNumeroOrdre] = useState((data.maxOrder + 1) || 0);
  const [file, setFile] = useState();
  const [facture, setFacture] = useState();
  const [cites, setCites] = useState("");

  const formValues = { type, file, emplacement, genre, espece, faune, sexe, quantite, prixAchat, dateCapture, typeAchat, detailsCapture, detailsAchat, numeroCoffret, numeroFacture, numeroOrdre, cites };

  const handleSubmit = useCallback(async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    const newValues = { ...formValues, prixAchat: parseFloat(formValues.prixAchat) };
    try {
      if (coffret)
        await updateCoffret(newValues, coffret._id)
      else
        await addCoffret(newValues);
      if (file)
        await postImage(file, numeroCoffret);
      if (facture)
        await postFacture(facture, numeroFacture);
      const res = await allCoffrets();
      setCoffrets(res?.data);
      const response = await allData();
      setData(response?.data);
      setIsModalOpen();
    } catch (_) {
      setLoading(false);
    }
  }, [coffret, setCoffrets, setIsModalOpen, formValues]);

  // Fill the form with the task to be updated
  useEffect(() => {
    if (coffret) {
      setType(coffret.type);
      setEmplacement(coffret.emplacement);
      setGenre(coffret.genre);
      setEspece(coffret.espece);
      setFaune(coffret.faune);
      setSexe(coffret.sexe);
      setQuantite(coffret.quantite);
      setPrixAchat(coffret.prixAchat);
      setDateCapture(coffret.dateCapture);
      setTypeAchat(coffret.typeAchat);
      setDetailsCapture(coffret.detailsCapture);
      setDetailsAchat(coffret.detailsAchat);
      setNumeroCoffret(coffret.numeroCoffret);
      setNumeroFacture(coffret.numeroFacture);
      setNumeroOrdre(coffret.numeroOrdre);
      setCites(coffret.cites);
    }
  }, [coffret]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { value, name } = e.target;

    if (name === "type") setType(value);
    else if (name === "emplacement") setEmplacement(value);
    else if (name === "cites") setCites(value);
    else if (name === "genre") setGenre(value.toUpperCase());
    else if (name === "espece") setEspece(value.toUpperCase());
    else if (name === "faune") setFaune(value);
    else if (name === "sexe") setSexe(value);
    else if (name === "quantite") setQuantite(parseInt(value));
    else if (name === "prixAchat") {
      const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
      const val = value.match(regex);
      if (val)
        setPrixAchat(val[0]);
    }
    else if (name === "dateCapture") setDateCapture(value.toUpperCase());
    else if (name === "typeAchat") setTypeAchat(value);
    else if (name === "detailsCapture") setDetailsCapture(value.toUpperCase());
    else if (name === "detailsAchat") setDetailsAchat(value.toUpperCase());
    else if (name === "numeroCoffret") setNumeroCoffret(value);
    else if (name === "numeroFacture") setNumeroFacture(value);
    else if (name === "numeroOrdre") setNumeroOrdre(parseInt(value));
  }, []);

  const checkSubmitValue = useCallback(() => {
    if (loading)
      return "Chargement..."
    return coffret ? "Mettre à jour" : "Créer"
  }, [loading, coffret]);

  const submitValue = useMemo(checkSubmitValue, [checkSubmitValue]);

  const fileSelected = (event: any) => {
    const file = event.target.files[0]
    setFile(file)
  }

  const factureSelected = (event: any) => {
    const file = event.target.files[0];
    setFacture(file)
  }

  return <TaskForm
    handleSubmit={handleSubmit}
    handleChange={handleChange}
    lastOrder={data.maxOrder}
    loading={loading}
    {...formValues}
    submitValue={submitValue}
    fileSelected={fileSelected}
    factureSelected={factureSelected}
    facture={facture}
  />
};