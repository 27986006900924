import { memo, useCallback, useContext, useState } from "react"
import { AiOutlineCloseCircle } from "react-icons/ai";

import { MemoizedTasks as Task } from "./Task.component";
import { allCoffrets, COFFRET, deleteTask } from "../../api/coffrets";
import { TasksContext } from "../../context/tasks/store";

import "./style.scss";

type PropsType = {
  coffret: COFFRET;
};

const TaskContainer = ({ coffret }: PropsType) => {
  const { setIsModalOpen, setCoffretToUpdate, setCoffretsToCsv, coffretsToCsv, setCoffrets } = useContext(TasksContext);
  const [openDeleteModel, setOpenDeleteModal] = useState(false);

  const update = useCallback(async (coffret: COFFRET) => {
    setIsModalOpen();
    setCoffretToUpdate(coffret);
  }, [setIsModalOpen]);

  const isExported = () => {
    const index = coffretsToCsv
      .map((object: COFFRET) => object._id)
      .indexOf(coffret._id);
    return (index >= 0) ? true : false;
  };

  const deleteItem = useCallback(async () => {
    if (typeof coffret._id === "string") {
      await deleteTask(coffret._id);
      setOpenDeleteModal(false);
      const res = await allCoffrets();
      setCoffrets(res?.data);
    }
  }, [coffret._id]);

  return (
    <>
      {
        openDeleteModel &&
        <div className="modal-delete">
          <div className="modal-content">
            <AiOutlineCloseCircle className="close" onClick={() => setOpenDeleteModal(false)} />
            <div>
              <span>Confirmer la suppression ?</span>
              <div className="input-block">
                <input type="button" value="ANNULER" onClick={() => setOpenDeleteModal(false)} />
                <input type="button" value="SUPPRIMER" onClick={deleteItem} />
              </div>
            </div>
          </div>
        </div>
      }
      <Task
        coffret={coffret}
        updateTask={update}
        mustExportToCSV={setCoffretsToCsv}
        isExported={isExported}
        setOpenDeleteModal={setOpenDeleteModal}
      />
    </>
  );
};

export const MemoizedTaskContainer = memo(TaskContainer);