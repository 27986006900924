import { AiOutlineUser } from "react-icons/ai";
import { BsKey } from "react-icons/bs";
import { Input, SubmitBtn } from "../Commons";

import "./style.scss";

export type AuthentificationPropsType = {
  handleSubmit: (e: React.SyntheticEvent) => void;
  username: string;
  handleChangeUsername: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  password: string,
  handleChangePwd: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  loading: boolean;
  title: string;
};

export const Authentification = ({
  handleSubmit,
  username,
  handleChangeUsername,
  password,
  handleChangePwd,
  loading,
  title
}: AuthentificationPropsType) => {
  return (
    <div className="auth-page">
      <form onSubmit={handleSubmit}>
        <Input
          value={username}
          onChange={handleChangeUsername}
          placeholder="Identifiant"
          Icon={AiOutlineUser}
          required
        />
        <Input
          value={password}
          onChange={handleChangePwd}
          placeholder="Mot de passe"
          type="password"
          Icon={BsKey}
          required
        />
        <SubmitBtn value={title} loading={loading} />
      </form>
    </div>
  );
};
