import { post } from "./fetch";

enum UserPath {
  REGISTER = "/user/register",
  LOGIN = "/user/login",
}

export const register = (username: string, password: string) => {
  return post({ username, password }, UserPath.REGISTER);
};

export const login = (username: string, password: string) => {
  return post({ username, password }, UserPath.LOGIN);
};
