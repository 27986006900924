import { createContext, useReducer, useMemo } from "react";

import { reducer } from "./reducer";
import { actions } from "./actions";
import { COFFRET } from "../../api/coffrets";
import { DATA } from "../../api/dataApi";

export type InitialStateType = {
  data: DATA,
  coffrets: COFFRET[];
  coffretsToCsv: COFFRET[];
  coffretToUpdate: number | null;
  setSearch: (searchName: string, value: string | number) => void;
  isModalOpen: boolean;
  setCoffrets: (coffrets: COFFRET[]) => void;
  setData: (data: DATA) => void;
  search: {
    genre: string,
    espece: string,
    type: string,
    faune: string,
    emplacement: string,
    ordre: string,
    numeroCoffret: string,
    numeroFacture: string,
    numeroOrdre: string,
  };
  setIsModalOpen: () => void;
  setCoffretToUpdate: (coffret: COFFRET | null) => void;
  setCoffretsToCsv: (coffret: COFFRET) => void;
  resetCoffretsToCsv: () => void;
};

const initialTasksState: InitialStateType = {
  data: {
    numerosCoffret: [],
    numerosFacture: [],
    numerosOrdre: [],
    maxOrder: 0,
  },
  coffrets: [],
  coffretsToCsv: [],
  coffretToUpdate: null,
  setSearch: () => null,
  isModalOpen: false,
  setCoffrets: (coffrets: COFFRET[]) => { },
  setData: (data: DATA) => { },
  search: {
    genre: "",
    espece: "",
    type: "",
    faune: "",
    emplacement: "",
    ordre: "",
    numeroCoffret: "",
    numeroFacture: "",
    numeroOrdre: "",
  },
  setIsModalOpen: () => { },
  setCoffretToUpdate: (coffret: COFFRET | null) => { },
  setCoffretsToCsv: (coffret: COFFRET) => { },
  resetCoffretsToCsv: () => { },
};

export const TasksContext = createContext(initialTasksState);

type PropsType = {
  children: JSX.Element;
};

export const TasksProvider = ({ children }: PropsType) => {
  const [state, dispatch] = useReducer(reducer, initialTasksState);

  const func = useMemo(() => {
    return {
      setCoffrets: (coffrets: COFFRET[]) =>
        dispatch({ type: actions.GET_COFFRETS, payload: coffrets }),
      setCoffretsToCsv: (coffret: COFFRET) =>
        dispatch({ type: actions.SET_COFFRETS_TO_CSV, payload: coffret }),
      setData: (data: DATA) =>
        dispatch({ type: actions.GET_DATA, payload: data }),
      setSearch: (searchName: string, value: string | number) =>
        dispatch({ type: actions.SET_SEARCH, payload: { searchName, value } }),
      setIsModalOpen: () =>
        dispatch({ type: actions.SET_IS_MODAL_OPEN }),
      setCoffretToUpdate: (coffret: COFFRET | null) =>
        dispatch({ type: actions.SET_COFFRET_TO_UPDATE, payload: coffret }),
      resetCoffretsToCsv: () =>
        dispatch({ type: actions.RESET_COFFRETS_TO_CSV, payload: [] }),
    };
  }, []);

  const value: typeof initialTasksState = useMemo(() => {
    return {
      coffrets: state.coffrets,
      coffretsToCsv: state.coffretsToCsv,
      data: state.data,
      search: state.search,
      coffretToUpdate: state.coffretToUpdate,
      isModalOpen: state.isModalOpen,
      ...func,
    };
  }, [
    state.coffrets,
    state.coffretsToCsv,
    state.data,
    state.search,
    state.isModalOpen,
    state.coffretToUpdate,
    func
  ]);

  return (
    <TasksContext.Provider value={value}>{children}</TasksContext.Provider>
  );
};
