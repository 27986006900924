import { useContext, useCallback, useMemo } from "react";

import { TasksContext } from "../../context/tasks/store";
import { TasksManager } from "./TasksManager.component";

const headers = [
  "NUM_AUTO",
  "LIGNE_1",
  "LIGNE_2",
  "LIGNE_3",
  "LIGNE_4",
  "LIGNE_5",
  "LIGNE_6",
  "LIGNE_7",
  "LIGNE_8",
  "LIGNE_9",
  "LIGNE_10",
];

export const TasksManagerContainer = () => {
  const {
    search,
    setSearch,
    setIsModalOpen,
    setCoffretToUpdate,
    data,
    coffretsToCsv,
    resetCoffretsToCsv
  } = useContext(TasksContext);

  const handleSearch =
    useCallback((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => setSearch(e.target.name, e.target.value), [setSearch]);
  const openModal = useCallback(() => {
    setCoffretToUpdate(null);
    setIsModalOpen();
  }, [setCoffretToUpdate, setIsModalOpen]);

  const newValues = useMemo(() => coffretsToCsv.map((item: any, index: number) => {
    return {
      NUM_AUTO: index + 1,
      LIGNE_1: item.sexe,
      LIGNE_2: item.genre,
      LIGNE_3: item.espece,
      LIGNE_4: item.detailsCapture,
      LIGNE_5: "",
      LIGNE_6: item.dateCapture,
      LIGNE_7: item.faune,
      LIGNE_8: item.numeroOrdre,
      LIGNE_9: "",
      LIGNE_10: "",
    };
  }), [coffretsToCsv]);

  const csvReport = useMemo(() => ({
    data: newValues,
    headers: headers,
    filename: 'ETIQUETTES.csv'
  }), [newValues]);

  return (
    <TasksManager
      data={data}
      handleModal={openModal}
      handleChangeSearch={handleSearch}
      search={search}
      coffretsToCsv={coffretsToCsv}
      csvReport={csvReport}
      resetCoffretsToCsv={resetCoffretsToCsv}
    />
  );
};