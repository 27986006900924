import { COFFRET } from "src/api/coffrets";
import { actions } from "./actions";
import { InitialStateType } from "./store";

type ActionType = {
  type: string;
  payload?: any;
};

export const reducer = (state: InitialStateType, action: ActionType) => {
  switch (action.type) {
    case actions.GET_TASKS:
      return { ...state, tasks: action.payload };
    case actions.RESET_COFFRETS_TO_CSV:
      return { ...state, coffretsToCsv: action.payload };
    case actions.SET_COFFRETS_TO_CSV:
      const index = state.coffretsToCsv
        .map((object: COFFRET) => {
          return object._id;
        })
        .indexOf(action.payload._id);
      if (index < 0)
        return {
          ...state,
          coffretsToCsv: [...state.coffretsToCsv, action.payload],
        };
      else {
        const arr = [...state.coffretsToCsv];
        arr.splice(index, 1);
        return {
          ...state,
          coffretsToCsv: arr,
        };
      }
    case actions.GET_DATA:
      return { ...state, data: action.payload };
    case actions.GET_COFFRETS:
      return { ...state, coffrets: action.payload };
    case actions.SET_SEARCH:
      return {
        ...state,
        search: {
          ...state.search,
          [action.payload.searchName]: action.payload.value,
        },
      };
    case actions.SET_IS_MODAL_OPEN:
      return { ...state, isModalOpen: !state.isModalOpen };
    case actions.SET_COFFRET_TO_UPDATE:
      return { ...state, coffretToUpdate: action.payload };
    default:
      return state;
  }
};
