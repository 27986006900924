import { memo, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import { TbFileInvoice } from "react-icons/tb";

import { COFFRET } from "../../api/coffrets";

import { PrintValue } from "../Commons";

import 'react-medium-image-zoom/dist/styles.css';
import "./style.scss";

type PropsType = {
  coffret: COFFRET;
  updateTask: (task: COFFRET) => Promise<void>;
  mustExportToCSV: (coffret: COFFRET) => void;
  isExported: () => boolean;
  setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const Task = ({ coffret, updateTask, mustExportToCSV, isExported, setOpenDeleteModal }: PropsType) => {
  const imgUrl = `/api/image/hd/${coffret.numeroCoffret}-HD.jpg`;
  const imgUrlHd = () => {
    fetch(`/api/image/hd/${coffret.numeroCoffret}-HD.jpg`,
      {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'image/jpeg',
        },
      })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        window.open(url, `_blank`)
      })
      .catch((err) => console.error(err));
  };

  const factureUrl = () => {
    fetch(`/api/facture/${coffret.numeroFacture}.pdf`,
      {
        method: "GET",
        headers: {
          'Accept': 'application/pdf',
          'Content-Type': 'application/pdf',
        },
      })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        window.open(url, `_blank`)
      })
      .catch(error => console.warn(error));
  };

  return (
    <li className="task-item">
      <div className="block-title">
        <span>{coffret.genre} - {coffret.espece}<span>({coffret.quantite}) <span>{coffret.numeroOrdre}</span></span></span>
        <button
          onClick={() => updateTask(coffret)}
        >
          <FiEdit />
        </button>
        <button
          onClick={factureUrl}
        >
          <TbFileInvoice />
        </button>
        <button
          onClick={() => mustExportToCSV(coffret)}
          className={isExported() ? "is-exported" : ""}
        >
          CSV
        </button>
        <button
          onClick={() => setOpenDeleteModal(true)}
        >
          <AiOutlineDelete />
        </button>
      </div>
      <div className="content">
        <div className="block-img">
          <img src={imgUrl} loading="lazy" alt="" onClick={imgUrlHd} />
        </div>
        <span className="infos">
          <span>
            <PrintValue label="type" value={coffret.type} />
            <PrintValue label="faune" value={coffret.faune} />
            <PrintValue label="sexe" value={coffret.sexe} />
          </span>
          <span>
            <PrintValue label="emplacement" value={coffret.emplacement} />
            <PrintValue label="date" value={coffret.dateCapture?.toUpperCase()} />
            <PrintValue label="N°&nbsp;coffret" value={coffret.numeroCoffret} />
          </span>
          <span>
            <PrintValue label="Type&nbsp;d'achat" value={coffret.typeAchat} />
            <PrintValue label="Valeur" value={coffret.prixAchat} />
            <PrintValue label="N°&nbsp;facture" value={coffret.numeroFacture} />
          </span>
        </span>
      </div>
      <div>
        <PrintValue label="Détails&nbsp;capture" value={coffret.detailsCapture?.toUpperCase()} />
        <PrintValue label="Détails&nbsp;d'achat" value={coffret.detailsAchat?.toUpperCase()} />
        <PrintValue label="Cites" value={coffret.cites?.toUpperCase()} />
      </div>
    </li>
  );
};

export const MemoizedTasks = memo(Task);
