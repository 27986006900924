import "./style.scss";

type SubmitBtnPropsType = {
  value: string;
  loading?: boolean;
};

export const SubmitBtn = ({
  value,
  loading,
}: SubmitBtnPropsType) => {
  return (
    <input
      className="submit-btn"
      type="submit"
      value={value}
      disabled={loading}
    />
  );
};

SubmitBtn.defaultProps = {
  loading: false,
}

type ButtonPropsType = {
  onClick: any;
  value: string;
  loading?: boolean;
};

export const Button = ({
  onClick,
  value,
  loading,
}: ButtonPropsType) => {
  return (
    <button className="submit-btn" onClick={onClick} disabled={loading}>{value}</button>
  );
};

Button.defaultProps = {
  loading: false,
}

