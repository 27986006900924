import { Navigate, Outlet } from "react-router-dom";
import jwt_decode from "jwt-decode";

export const ProtectedRoutes = () => {
  const token = localStorage.getItem("token");

  if (token) {
    try {
      const { created_at }: any = jwt_decode(token);

      if ((Date.now() - created_at) > 3600000)
        throw new Error("Session expirée");
      return <Outlet />;
    } catch (err: any) {
      alert(err.message);
      localStorage.removeItem("token");
    }
  }
  return <Navigate to="/login" replace />;
};