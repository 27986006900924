import { get } from "./fetch";

export type DATA = {
  numerosCoffret: string[];
  numerosFacture: string[];
  numerosOrdre: number[];
  maxOrder: number;
};

enum DataPath {
  ALL_DATA = "/data",
}

export const allData = () => {
  return get(DataPath.ALL_DATA);
};
