import { post, get, update, deleteItem } from "./fetch";

export type COFFRET = {
  _id?: number;
  type?: string;
  emplacement?: string;
  faune?: string;
  espece?: string;
  genre?: string;
  sexe?: string;
  quantite?: number;
  dateCapture?: string;
  detailsCapture?: string;
  typeAchat?: string;
  prixAchat?: number;
  detailsAchat?: string;
  numeroCoffret?: string;
  numeroFacture?: string;
  numeroOrdre?: number;
  numero?: number;
  owner?: number;
  updatedAt?: Date;
  createdAt?: Date;
  cites?: string;
};

enum CoffretPath {
  ALL_COFFRETS = "/coffret",
  ADD_COFFRET = "/coffret",
  UPDATE_COFFRET = "/coffret/",
  DELETE_COFFRET = "/coffret/",
}

export const allCoffrets = () => {
  return get(CoffretPath.ALL_COFFRETS);
};

export const addCoffret = (coffret: COFFRET) => {
  return post(coffret, CoffretPath.ADD_COFFRET);
};

export const updateCoffret = (coffret: COFFRET, id: number | undefined) => {
  return update(coffret, CoffretPath.UPDATE_COFFRET + id);
};

export const deleteTask = (id: number) => {
  return deleteItem(CoffretPath.UPDATE_COFFRET + id);
};
