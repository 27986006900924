import { Dispatch, memo, SetStateAction, useMemo, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import { Task } from "..";

import { COFFRET } from "../../api/coffrets";

import "./style.scss";

type PropsType = {
  filteredCoffrets: any;
  coffretsShowedNbr: number;
  setCoffretsShowedNbr: Dispatch<SetStateAction<number>>;
  coffretsShowed: COFFRET[];
  setCoffretsShowed: Dispatch<SetStateAction<never[]>>;
}

const TasksList = ({ filteredCoffrets, setCoffretsShowed, coffretsShowed, setCoffretsShowedNbr, coffretsShowedNbr }: PropsType) => {
  const len = filteredCoffrets.length;

  useEffect(() => {
    setCoffretsShowed(filteredCoffrets.slice(0, coffretsShowedNbr));
  }, [filteredCoffrets]);

  const fetchMoreData = () => {
    setCoffretsShowed(filteredCoffrets.slice(0, coffretsShowedNbr + 10));
    setCoffretsShowedNbr(coffretsShowedNbr + 10);
  };

  return (
    <>
      
<div className="collection-number">{len} Specimen{len > 1 ? "s" : ""}</div>


      <InfiniteScroll
        dataLength={coffretsShowedNbr}
        next={fetchMoreData}
        hasMore={coffretsShowedNbr < filteredCoffrets.length}
        loader={<h4>Chargement...</h4>}
      >
        <ul className="tasks-list">
          {coffretsShowed.map((coffret: COFFRET) => <Task
            key={coffret._id}
            coffret={coffret}
          />)}
        </ul>
      </InfiniteScroll>
    </>
  )
};

export const MemoizedTasksList = memo(TasksList);
