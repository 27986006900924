export const actions = {
  GET_TASKS: "GET_TASKS",
  GET_COFFRETS: "GET_COFFRETS",
  GET_DATA: "GET_DATA",
  UPDATE_TASK: "UPDATE_TASK",
  DELETE_TASK: "DELETE_TASK",
  SET_SEARCH: "SET_SEARCH",
  SET_IS_MODAL_OPEN: "SET_IS_MODAL_OPEN",
  TOGGLE_SHOW_OWN_TASKS: "TOGGLE_SHOW_OWN_TASKS",
  TOGGLE_SHOW_FLAGGED_TASKS: "TOGGLE_SHOW_FLAGGED_TASKS",
  SET_COFFRET_TO_UPDATE: "SET_COFFRET_TO_UPDATE",
  SET_COFFRETS_TO_CSV: "SET_COFFRETS_TO_CSV",
  RESET_COFFRETS_TO_CSV: "RESET_COFFRETS_TO_CSV",
};
