import "./style.scss";

export const PrintValue = ({ label, value }: any) => {
  return (
    <div className="block-print-value">
      <span className="label">{label}&thinsp;:</span>
      <span className="value">{value || "-"}</span>
    </div>
  );
};
