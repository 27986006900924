import { FaFileInvoiceDollar } from "react-icons/fa";
import { BsInbox } from "react-icons/bs";
import { AiOutlineFieldNumber } from "react-icons/ai";



import { Input, Select, SubmitBtn } from "../Commons";

import "./style.scss";
import { COLLECTION_TYPES, FAUNES, COLLECTION_EMPLACEMENTS, SEXES, TYPES_ACHAT } from "../../api/data";

type PropsType = {
  handleSubmit: (e: React.SyntheticEvent) => Promise<void>;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
  lastOrder: number;
  loading: boolean;
  type: string;
  emplacement: string;
  genre: string;
  espece: string;
  faune: string;
  sexe: string;
  quantite: number;
  prixAchat: string;
  dateCapture: string;
  typeAchat: string;
  detailsCapture: string;
  detailsAchat: string;
  numeroCoffret: string;
  numeroFacture: string;
  numeroOrdre: number;
  submitValue: string;
  file: any;
  fileSelected: (e: any) => void;
  factureSelected: (e: any) => void;
  facture: any;
  cites: string;
}

export const TaskForm = ({
  handleSubmit,
  handleChange,
  lastOrder,
  loading,
  type,
  cites,
  emplacement,
  genre,
  espece,
  faune,
  sexe,
  quantite,
  prixAchat,
  dateCapture,
  typeAchat,
  detailsCapture,
  detailsAchat,
  numeroCoffret,
  numeroFacture,
  numeroOrdre,
  submitValue,
  file,
  fileSelected,
  factureSelected,
  facture,
}: PropsType) => {
  const imgUrl = `/api/image/hd/${numeroCoffret}-HD.jpg`;

  return (
    <form onSubmit={handleSubmit} className="form-task">
      <div className="raw">
        <Input
          value={genre}
          onChange={handleChange}
          placeholder="genre"
          name="genre"
          required
        />
        <Input
          value={espece}
          onChange={handleChange}
          placeholder="espèce"
          name="espece"
          required
        />
      </div>
      <div className="raw">
        <div className="column">
          <Select name="type" value={type} placeholder="TYPE" onChange={handleChange} options={COLLECTION_TYPES} required />
          <Select name="emplacement" value={emplacement} placeholder="EMPLACEMENT" onChange={handleChange} options={COLLECTION_EMPLACEMENTS} required />
        </div>
        <div className="column">
          <Select name="faune" value={faune} placeholder="FAUNE" onChange={handleChange} options={FAUNES} required />
          <Select name="sexe" value={sexe} placeholder="SEXE" onChange={handleChange} options={SEXES} required />
        </div>
      </div>
      <div className="raw">
        <Input
          value={quantite}
          type="number"
          onChange={handleChange}
          placeholder="Qté en collection"
          min={1}
          max={10}
          name="quantite"
          required
        />
        <Input
          value={prixAchat.toString()}
          type="text"
          onChange={handleChange}
          placeholder="Valeur en €"
          name="prixAchat"
          required
        />
      </div>
      <div className="raw">
        <div className="column">
          <Input
            value={dateCapture}
            onChange={handleChange}
            placeholder="Date de capture"
            name="dateCapture"
            required
          />
          <textarea
            className="field"
            placeholder="Détails sur la capture "
            required
            value={detailsCapture}
            onChange={handleChange}
            name="detailsCapture"
          />
        </div>
        <div className="column">
          <Select name="typeAchat" value={typeAchat} placeholder="TYPE D'ACHAT" onChange={handleChange} options={TYPES_ACHAT} required />
          <textarea
            className="field"
            placeholder="Détails sur l'achat"
            value={detailsAchat}
            onChange={handleChange}
            name="detailsAchat"
          />
        </div>
      </div>
      <div className="raw">
        <Input name="numeroCoffret" value={numeroCoffret} placeholder="N° COFFRET" onChange={handleChange} Icon={BsInbox} required />
        <Input name="numeroFacture" value={numeroFacture} placeholder="N° FACTURE" onChange={handleChange} Icon={FaFileInvoiceDollar} required />
        <Input name="numeroOrdre" value={numeroOrdre} placeholder={`N° ORDRE (dernier: ${lastOrder})`} onChange={handleChange} Icon={AiOutlineFieldNumber} required />
      </div>
      <div className="raw">
        <Input
          onChange={fileSelected}
          placeholder="Image"
          name="image"
          type="file"
          accept="image/*"
        />
        <Input
          onChange={factureSelected}
          placeholder="Facture"
          name="facture"
          type="file"
          accept="application/pdf"
        />
      </div>
      <div className="raw">
        <Input name="cites" value={cites} placeholder="CITES" onChange={handleChange} />
      </div>
      <div className="raw">
        <img src={file ? URL.createObjectURL(file) : imgUrl} loading="lazy" alt="" />
      </div>
      <SubmitBtn value={submitValue} loading={loading} />
    </form>
  );
};